const konaJobsList = [
  {
    title: "Submarine Pilots",
    description:
      "Position is responsible for driving the Atlantis tourist submarine while also functioning as a crew member. Must have previous Atlantis Submarines experience, have completed the Atlantis Submarines Pilot training program, and have an appropriate USCG license. Previous boating and SCUBA experience is helpful. A passion for excellent guest service is essential. The full-time flexible schedule will include weekends and holidays. Pay will be $30/hour and up, depending on experience. Supervisory positions are available with experience.",
  },
  {
    title: "Co-Pilot (Deckhand/Narrator)",
    description:
      "Position is responsible for delivering educational and entertaining marine narrations onboard our submarine and transport vessel while also functioning as a crew member. This entry level position helps prepare for entry into our Submersible Pilot training program. Previous boating and SCUBA experience is helpful. A passion for excellent guest service is desirable. The full-time flexible schedule will include weekends and holidays. Pay rate is $20 per hour.",
  },
];

export default konaJobsList;
